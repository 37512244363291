import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import { ImageTemplate } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells() {
  return [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      minWidth: 100,
    },
    {
      attributeName: 'name',
      title: 'Name',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'active',
      title: 'Status',
      displayPriority: 1,
      minWidth: 100,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: result => ({
        text: result.active ? 'Active' : 'Inactive',
        color: result.active ? COLORS.success : GRAYSCALE.ground,
      }),
    },
    {
      attributeName: 'timezone',
      title: 'Timezone',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'country',
      title: 'Country',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'code',
      title: 'Code',
      displayPriority: 1,
      minWidth: 100,
    },
    {
      attributeName: 'public_picture_url',
      title: 'Image',
      displayPriority: 1,
      minWidth: 150,
      type: RECORD_TYPES.component,
      component: ImageTemplate,
      props: city => ({
        url: city.public_picture_url,
        key: city.uuid,
        height: 40,
      }),
    },
    {
      attributeName: 'gps_lat',
      title: 'Latitude',
      displayPriority: 0,
      minWidth: 150,
    },
    {
      attributeName: 'gps_lng',
      title: 'Longitude',
      displayPriority: 0,
      minWidth: 150,
    },
    {
      attributeName: 'cs_operator',
      title: 'Operator',
      displayPriority: 0,
      minWidth: 150,
    },
    {
      attributeName: 'spot_url',
      title: 'Spot url',
      displayPriority: 0,
      minWidth: 150,
      type: RECORD_TYPES.template,
      template: city => {
        const linkClasses = 'emobg-link-primary emobg-body-2';
        return city.spot_url
          ? `<a class="${linkClasses}" href="${city.spot_url}" target="_blank">Spot URL</a>`
          : FALLBACK_MESSAGE.dash;
      },
    },
  ];
}
