<script>
import moment from 'moment-timezone';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import toUpper from 'lodash/toUpper';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import { snakeCaseKeys } from '@emobg/web-utils';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  CancelButton,
  ContentCellComponent,
  DragFileComponent,
  GenericModalComponent,
  GoogleLocationSelect,
  GoogleMapComponent,
  StoreNotificationComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'CitiesForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
    DragFileComponent,
    MuiSelect,
    GoogleMapComponent,
    GoogleLocationSelect,
    ContentCellComponent,
  },
  props: {
    city: {
      type: Object,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      currentImages: [],
      files: [],
      address: null,
      inputs: {
        active: false,
        code: null,
        countryFk: null,
        defaultCsOperatorFk: null,
        gpsLat: null,
        gpsLng: null,
        name: null,
        picture: '',
        spotUrl: '',
        timezone: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.cities, {
      cityStatus: state => state.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    isEditing() {
      return !!this.city;
    },
    hasSameValues() {
      return this.isEditing ? isEqual(this.inputs, this.originalInputs) : false;
    },
    modalTitle() {
      return this.isEditing ? 'Edit city' : 'Create new city';
    },
    timezones() {
      return map(moment.tz.names(), item => ({
        value: item,
        label: item,
      }));
    },
    locationMarkers() {
      const markers = [];

      if (this.inputs.gpsLat && this.inputs.gpsLng) {
        markers.push({
          id: 'location-marker',
          position: {
            lat: parseFloat(this.inputs.gpsLat),
            lng: parseFloat(this.inputs.gpsLng),
          },
        });
      }
      return markers;
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.inputs.defaultCsOperatorFk = this.activeOperatorId;

    if (this.city) {
      this.inputs.name = this.city.name;
      this.inputs.active = !!this.city.active;
      this.inputs.code = this.city.code;
      this.inputs.timezone = this.city.timezone;
      this.inputs.countryFk = this.city.countryFk;
      this.inputs.spotUrl = this.city.spotUrl ? this.city.spotUrl : '';
      this.inputs.defaultCsOperatorFk = this.city.defaultCsOperatorFk;
      this.inputs.gpsLat = parseFloat(this.city.gpsLat);
      this.inputs.gpsLng = parseFloat(this.city.gpsLng);
      this.address = {
        lat: this.inputs.gpsLat,
        lng: this.inputs.gpsLng,
      };

      if (this.city.picture) {
        this.currentImages = [{ name: `${this.inputs.name}.png`, src: get(this, 'city.publicPictureUrl') }];
      }

      this.originalInputs = cloneDeep(this.inputs);
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.cities, ['postEditCity', 'postCity']),
    updateImage(files) {
      this.inputs.picture = files.length ? files[0] : '';
    },
    onFileChange() {
      this.currentImages = this.files.length ? [] : this.currentImages;
    },
    transformToUpper(value) {
      this.inputs.code = toUpper(value);
    },
    setLatLng(place) {
      this.inputs.gpsLat = place.geometry.location.lat();
      this.inputs.gpsLng = place.geometry.location.lng();
    },
    async cityRequest() {
      const formData = new FormData();

      each(snakeCaseKeys(this.inputs), (item, key) => {
        formData.append(key, item);
      });

      if (this.isEditing) {
        formData.append('_method', 'PUT');
      }

      const method = this.isEditing ? this.postEditCity : this.postCity;
      const data = this.isEditing ? { cityId: this.city.id, data: formData } : formData;
      const action = this.isEditing ? 'edited' : 'created';

      await method(data);

      if (!this.cityStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: `City successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: '',
        });

        this.onSuccess();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.large"
    :title="modalTitle"
    class="CityForm"
    data-test-id="city-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.cities"
        :is-editing="isEditing"
        element="city"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div
            v-if="isEditing"
            class="mb-4 col-6"
          >
            <ui-toggle
              :value="inputs.active"
              :text="inputs.active ? 'Active' : 'Inactive'"
              label="Status"
              name="status"
              data-test-id="active-toggle"
              @changevalue="({ detail }) => inputs.active = detail"
            />
          </div>
          <div :class="['mb-4 col-6', { 'col-12': !isEditing }]">
            <MuiInputText
              v-model="inputs.name"
              v-validate="{
                isRequired: true,
              }"
              label="Name*"
              class="w-100"
              name="name"
              placeholder="Enter name"
              data-test-id="name-input"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-4">
              <MuiAlgoliaSelect
                v-model="inputs.countryFk"
                v-validate="{
                  isRequired: true,
                }"
                :title="country => country.name"
                :index="ALGOLIA_INDEXES.countries"
                label="Country*"
                placeholder="Select country"
                class="w-100"
                path-value="id"
                name="country"
                data-test-id="country-select"
              />
            </div>

            <div class="mb-4">
              <MuiInputText
                v-model="inputs.spotUrl"
                label="Spot url"
                class="w-100"
                name="spot"
                placeholder="Enter spot url"
                data-test-id="spot-input"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="mb-4">
              <MuiSelect
                v-model="inputs.timezone"
                v-validate="{
                  isRequired: true,
                }"
                :options="timezones"
                label="Timezone*"
                placeholder="Select timezone"
                name="timezone"
                data-test-id="timezone-select"
                class="w-100"
              />
            </div>

            <div class="mb-4">
              <MuiInputText
                v-model="inputs.code"
                v-validate="{
                  isRequired: true,
                }"
                label="Code*"
                class="w-100"
                name="code"
                placeholder="Enter code"
                data-test-id="code-input"
                maxlength="3"
                @input="transformToUpper"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Image
            </label>
            <DragFileComponent
              v-model="files"
              :existing-files="currentImages"
              :draggable-height="200"
              show-preview
              data-test-id="file-input"
              @files="updateImage"
              @change="onFileChange"
              @existingFileRemoved="files => currentImages = files"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <GoogleLocationSelect
              v-model="address"
              v-validate="{
                isRequired: true,
              }"
              label="Address*"
              placeholder="Enter an address"
              data-test-id="internal_address-input"
              name="name"
              class="w-100"
              @update:place="setLatLng"
            />
          </div>

          <div class="col-6 mb-4">
            <ContentCellComponent
              :value="inputs.gpsLat"
              label="Latitude"
              data-test-id="latitude-text"
            />
          </div>

          <div class="col-6 mb-4">
            <ContentCellComponent
              :value="inputs.gpsLng"
              label="Longitude"
              data-test-id="longitude-text"
            />
          </div>

          <div class="col-12">
            <GoogleMapComponent
              :markers="locationMarkers"
              data-test-id="map"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="$emit('closeModal')"
        />
        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="cityStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="cityRequest"
        >
          {{ isEditing ? 'Save' : 'Create' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
