var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CitiesListView", attrs: { "data-test-id": "cities-view" } },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c(
            "h1",
            {
              staticClass: "flex-grow-1",
              attrs: { "data-test-id": "title-text" },
            },
            [_vm._v(" Cities ")]
          ),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Create new city ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "cities",
            attrs: {
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.exportColumns,
              facets: _vm.facets,
              filters: _vm.getOperatorFilter(),
              "table-config": _vm.contentCells,
              index: _vm.ALGOLIA_INDEXES.cities,
              "is-export-enabled": "",
              "data-test-id": "table",
            },
          }),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("CityForm", {
            attrs: { city: _vm.cityToEdit, "on-success": _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }