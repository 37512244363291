import { isFalsyString } from '@emobg/web-utils';
export { contentCells } from './contentCells';

export const facets = () => [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'active',
      transformValue: value => isFalsyString(value) ? 'Inactive' : 'Active',
    },
  },
];
