<script>
import { PageView } from '@/components';

export default {
  name: 'CitiesView',
  components: {
    PageView,
  },
};
</script>

<template>
  <PageView class="CitiesView d-flex flex-column flex-fill">
    <RouterView />
  </PageView>
</template>

