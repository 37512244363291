var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CityForm",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.large,
          title: _vm.modalTitle,
          "data-test-id": "city-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.cities,
                    "is-editing": _vm.isEditing,
                    element: "city",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _vm.isEditing
                        ? _c(
                            "div",
                            { staticClass: "mb-4 col-6" },
                            [
                              _c("ui-toggle", {
                                attrs: {
                                  value: _vm.inputs.active,
                                  text: _vm.inputs.active
                                    ? "Active"
                                    : "Inactive",
                                  label: "Status",
                                  name: "status",
                                  "data-test-id": "active-toggle",
                                },
                                on: {
                                  changevalue: ({ detail }) =>
                                    (_vm.inputs.active = detail),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { class: ["mb-4 col-6", { "col-12": !_vm.isEditing }] },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Name*",
                              name: "name",
                              placeholder: "Enter name",
                              "data-test-id": "name-input",
                            },
                            model: {
                              value: _vm.inputs.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "name", $$v)
                              },
                              expression: "inputs.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiAlgoliaSelect", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                title: (country) => country.name,
                                index: _vm.ALGOLIA_INDEXES.countries,
                                label: "Country*",
                                placeholder: "Select country",
                                "path-value": "id",
                                name: "country",
                                "data-test-id": "country-select",
                              },
                              model: {
                                value: _vm.inputs.countryFk,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "countryFk", $$v)
                                },
                                expression: "inputs.countryFk",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              staticClass: "w-100",
                              attrs: {
                                label: "Spot url",
                                name: "spot",
                                placeholder: "Enter spot url",
                                "data-test-id": "spot-input",
                              },
                              model: {
                                value: _vm.inputs.spotUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "spotUrl", $$v)
                                },
                                expression: "inputs.spotUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiSelect", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                options: _vm.timezones,
                                label: "Timezone*",
                                placeholder: "Select timezone",
                                name: "timezone",
                                "data-test-id": "timezone-select",
                              },
                              model: {
                                value: _vm.inputs.timezone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "timezone", $$v)
                                },
                                expression: "inputs.timezone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Code*",
                                name: "code",
                                placeholder: "Enter code",
                                "data-test-id": "code-input",
                                maxlength: "3",
                              },
                              on: { input: _vm.transformToUpper },
                              model: {
                                value: _vm.inputs.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "code", $$v)
                                },
                                expression: "inputs.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Image ")]
                          ),
                          _c("DragFileComponent", {
                            attrs: {
                              "existing-files": _vm.currentImages,
                              "draggable-height": 200,
                              "show-preview": "",
                              "data-test-id": "file-input",
                            },
                            on: {
                              files: _vm.updateImage,
                              change: _vm.onFileChange,
                              existingFileRemoved: (files) =>
                                (_vm.currentImages = files),
                            },
                            model: {
                              value: _vm.files,
                              callback: function ($$v) {
                                _vm.files = $$v
                              },
                              expression: "files",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 mb-4" },
                        [
                          _c("GoogleLocationSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Address*",
                              placeholder: "Enter an address",
                              "data-test-id": "internal_address-input",
                              name: "name",
                            },
                            on: { "update:place": _vm.setLatLng },
                            model: {
                              value: _vm.address,
                              callback: function ($$v) {
                                _vm.address = $$v
                              },
                              expression: "address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("ContentCellComponent", {
                            attrs: {
                              value: _vm.inputs.gpsLat,
                              label: "Latitude",
                              "data-test-id": "latitude-text",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("ContentCellComponent", {
                            attrs: {
                              value: _vm.inputs.gpsLng,
                              label: "Longitude",
                              "data-test-id": "longitude-text",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("GoogleMapComponent", {
                            attrs: {
                              markers: _vm.locationMarkers,
                              "data-test-id": "map",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: _vm.hasSameValues || !_vm.isFormValid,
                          loading: _vm.cityStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.cityRequest },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.isEditing ? "Save" : "Create") + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }