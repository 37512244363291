<script>
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import {
  contentCells,
  facets,
} from './config/index';
import CityForm from './components/CitiesForm';

export default {
  name: 'CitiesListView',
  components: {
    MuiAlgoliaList,
    CityForm,
  },
  data() {
    return {
      isLoading: false,
      cityToEdit: null,
      isModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells();
    this.exportColumns = this.contentCells;
    this.facets = facets();
    this.itemsActions = [
      {
        label: 'Edit city',
        method: city => {
          this.cityToEdit = camelCaseKeys(city);
          this.isModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.cityToEdit = null;
    },
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.cities, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <div
    class="CitiesListView"
    data-test-id="cities-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1
        class="flex-grow-1"
        data-test-id="title-text"
      >
        Cities
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="isModalVisible = true"
      >
        Create new city
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="cities"
        :item-actions="itemsActions"
        :export-columns="exportColumns"
        :facets="facets"
        :filters="getOperatorFilter()"
        :table-config="contentCells"
        :index="ALGOLIA_INDEXES.cities"
        is-export-enabled
        data-test-id="table"
      />
    </div>
    <CityForm
      v-if="isModalVisible"
      :city="cityToEdit"
      :on-success="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>

